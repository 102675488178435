import React from 'react'
import { Typography, makeStyles, Grid } from '@material-ui/core'
import {
  BackgroundImage,
  ContainerWithNavBar,
  GridItem,
  HorizontalGrid,
  ViewportBox,
  Markdown,
  MediaPlayer,
} from '@meowwolf/mw-denver-react'
import { Article } from './Newsfeed'
import NavBar from '../common/NavBar'
import bgImgSrc from './assets/newsfeed-article-bg.png'
import bannerImgSrc from './assets/newsfeed-article-header.png'
import logoImgSrc from './assets/newsfeed-logo-bold.png'
import awardImgSrc from './assets/newsfeed-award.png'

const BANNER_PADDING_PX = 30

interface Props {
  article: Article
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
  },
  container: {
    marginTop: 20,
    padding: 0,
    margin: 0,
  },
  bannerContainer: {
    flexDirection: 'row',
  },
  bannerWrapper: {
    display: 'inline-block',
    position: 'relative',
    height: '150px',
    margin: '0 auto',
    textAlign: 'center',
  },
  banner: {
    maxHeight: '100%',
  },
  logo: {
    position: 'absolute',
    right: BANNER_PADDING_PX,
    bottom: 0,
    height: '2rem',
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: '2.3rem',
    fontSize: '2.875rem',
    lineHeight: '3.5rem',
  },
  award: {
    marginTop: '2.25rem',
    width: 100,
    height: 100,
  },
  byline: {
    marginTop: 8,
  },
  characterName: {
    fontWeight: 800,
    color: theme.palette.primary.main,
  },
  paragraph: {
    marginTop: '2.5rem',
  },
  videoWrapper: {
    width: '100%',
    height: 'auto',
    padding: '3rem 0',
  },
  articleContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}))

const NewsfeedArticle: React.FC<Props> = ({ article, onClose }) => {
  const classes = useStyles()
  const paragraphs = article.body.split('\n').map((p, idx) => (
    <div key={idx} className={classes.paragraph}>
      <Markdown>{p}</Markdown>
    </div>
  ))

  return (
    <ViewportBox>
      <ContainerWithNavBar
        className={classes.root}
        bgImgSrc={bgImgSrc}
        navbar={() => <NavBar title="Converged News" onClickGoBack={onClose} goBackText=" " />}
      >
        <Grid container justifyContent="space-between" alignContent="center" className={classes.articleContainer}>
          <GridItem xs={12} className={classes.container}>
            {/* Banner */}
            <Grid container justifyContent="center" alignItems="center">
              <div className={classes.bannerWrapper}>
                <img className={classes.banner} src={article.bannerImgSrc || bannerImgSrc} alt="article image" />
                <img className={classes.logo} src={article.iconImgSrc || logoImgSrc} alt="src image" />
              </div>
            </Grid>
            <HorizontalGrid height="auto">
              <GridItem flexGrow>
                {/* Title */}
                <Typography variant="h1" className={classes.title}>
                  {article.title}
                </Typography>
                {/* Byline */}
                <Typography variant="body2" className={classes.byline}>
                  Published by <span className={classes.characterName}>{article.characterName}</span>
                </Typography>
              </GridItem>
              {/* Award */}
              <GridItem>
                <BackgroundImage src={awardImgSrc} className={classes.award} />
              </GridItem>
            </HorizontalGrid>
            {article.video && (
              <iframe
                src={`https://player.vimeo.com/video/${article.video.src}`}
                style={{ border: 0, width: '100%' }}
              />
            )}
            {paragraphs}
          </GridItem>
        </Grid>
      </ContainerWithNavBar>
    </ViewportBox>
  )
}

export default NewsfeedArticle
